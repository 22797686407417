import { buildUrl } from "./cmsUrlBuilder";

export const cmsUrls = {
  modules: () => buildUrl("modules", { populate: ["*"] }),
  parts: () => buildUrl("parts", { populate: ["module"] }),

  moduleRelation: (page = "1") =>
    buildUrl("module-relations", {
      populate: ["part", "module"],
      pagination: [
        { matchers: ["pageSize"], value: "100" },
        { matchers: ["page"], value: page },
      ],
    }),

  currentPart: ({
    partOrder,
    moduleId,
  }: {
    partOrder: string;
    moduleId: string;
  }) =>
    buildUrl("module-relations", {
      populate: ["part"],
      filters: [
        {
          matchers: ["part_order"],
          value: partOrder,
        },
        {
          matchers: ["module", "id", "$eq"],
          value: moduleId,
        },
      ],
    }),

  getPartGrowthworks: ({
    partId,
    expertId,
    moduleId,
  }: {
    partId: string;
    expertId: string;
    moduleId: string;
  }) =>
    buildUrl("module-relations", {
      populate: ["gw.expert", "gw.image"],
      filters: [
        {
          matchers: ["part", "id", "$eq"],
          value: partId,
        },
        {
          matchers: ["module", "id", "$eq"],
          value: moduleId,
        },
        {
          matchers: ["$or", "0", "gw", "expert", "mgmt_uid", "$eq"],
          value: expertId,
        },
        {
          matchers: ["$or", "1", "gw", "expert", "id", "$null"],
          value: "null",
        },
        {
          matchers: ["$or", "2", "gw", "expert", "mgmt_uid", "$eq"],
          value: process.env.DEFAULT_EXPERT_UUID || "",
        },
      ],
    }),

  youreOnYourWay: (tier: number) =>
    buildUrl("youre-on-your-way-pages", {
      populate: ["*"],
      filters: [{ matchers: ["tier", "$eq"], value: tier }],
    }),

  videoGrowthworks: (query: string) =>
    `/module-relations?${query}&populate=gw.image`,

  expert: (expertId: string) =>
    buildUrl("experts", {
      populate: ["*"],
      filters: [
        {
          matchers: ["mgmt_uid", "$eq"],
          value: expertId,
        },
      ],
    }),

  sessionEvents: () => buildUrl("session-events", { populate: ["*"] }),

  subscriptionDetails: (tier: number) =>
    buildUrl("subscription-details", {
      filters: [{ matchers: ["tier", "$eq"], value: tier }],
    }),

  subscriptionProfileDetails: (cms_id: string) =>
    `/subscription-profile-details/${cms_id}`,

  defaultRecommendedPathway: () =>
    buildUrl("default-recommended-pathway", { populate: ["*"] }),
};

export const mainApiUrls = {
  members: () => "/member/",
  member: (uuid: string) => `/member/${uuid}/`,
  updateModule: (memberId: string, moduleId: string) =>
    `/members/${memberId}/modules/${moduleId}/`,
  modules: (memberId: string) => `/members/${memberId}/modules/`,
  matchingModules: ({
    memberId,
    modules_list,
  }: {
    memberId: string;
    modules_list: string;
  }) => `/member/${memberId}/matching-modules/?${modules_list}`,
  memberGrowth: (memberId: string) => `members/${memberId}/growthworks/`,
  memberGrowthById: ({
    memberId,
    growthworkId,
  }: {
    memberId: string;
    growthworkId: string;
  }) => `/members/${memberId}/growthworks/${growthworkId}/`,

  completedGrowthworksCount: ({
    memberId,
    gwList,
  }: {
    memberId: string;
    gwList: string;
  }) => `/members/${memberId}/growth/completed-count/?${gwList}`,

  completedGrowthworksTasks: ({
    memberId,
    gwList,
  }: {
    memberId: string;
    gwList: string;
  }) => `/members/${memberId}/growth/completed-tasks/?${gwList}`,

  expert: (memberId: string) => `/member/${memberId}/expert/`,

  scheduledSession: (memberId: string) =>
    `/member/${memberId}/scheduled-sessions/`,

  assignedPart: (memberId: string) =>
    `/members/${memberId}/parts/assigned-part/`,

  welcomeSessionCompleted: (memberId: string) =>
    `/member/${memberId}/welcome-session-completed/`,

  matchingSessionCompleted: (memberId: string) =>
    `/member/${memberId}/matching-session-completed/`,

  memberGrowthworksCmsIds: (memberId: string) => `/members/${memberId}/growth/`,

  sessionLinks: (memberId: string) => `/members/${memberId}/session-links/`,

  onboardingStatus: (memberId: string) =>
    `/member/${memberId}/onboarding-status/`,

  addPartner: (memberId: string) => `/member/${memberId}/add-partner/`,

  completeOnboardingEvent: (memberId: string) =>
    `/member/${memberId}/complete-onboarding-app-event/`,

  streamToken: (userId: string) => `/stream_token?user_id=${userId}`,

  frontHash: (email: string) => `/front_hash?email=${email}`,

  publicSession: (sessionId: string) => `public/sessions/${sessionId}/`,

  subscription: (memberId: string) => `account/${memberId}/subscription/`,

  account: (memberId: string) => `member/${memberId}/account`,

  subscriptionReset: (memberId: string) =>
    `accounts/request-subscription-reset/?uuid=${memberId}`,

  subscriptionResetEvent: (memberId: string) =>
    `accounts/popup-reactivation-shown-event/?uuid=${memberId}`,

  memberPlanDetails: (memberId: string) =>
    `members/${memberId}/account/plan/details/`,

  subscriptionStatus: (memberId: string) =>
    `account/${memberId}/subscription/status`,

  memberPart: ({ memberId, partId }: { memberId: string; partId: string }) =>
    `members/${memberId}/parts/${partId}/`,

  getMemberStatusByPhoneNumber: (phoneNumber: string) =>
    `public/member-restricted-details?phone_number=${phoneNumber}`,

  getMemberStatusByEmail: (email: string) =>
    `public/member-restricted-details?email=${email}`,

  requestAccessLink: (uuid: string) =>
    `public/members/${uuid}/request_access_link/`,

  getSurvey: ({ uuid, surveyId }: { uuid: string; surveyId: string }) =>
    `members/${uuid}/survey_responses?survey_id=${surveyId}`,

  surveyClickEvent: ({ uuid, surveyId }: { uuid: string; surveyId: string }) =>
    `members/${uuid}/survey_responses/click-event/?survey_id=${surveyId}`,

  videoTask: ({
    memberId,
    pathwayId,
  }: {
    memberId: string;
    pathwayId: string;
  }) => `members/${memberId}/growth/video-tasks/?pathway_id=${pathwayId}`,

  getChatMembers: "chat/get_members/",

  memberFeedback: (memberId: string) => `members/${memberId}/feedbacks/`,

  completedSessionsCount: (memberId: string) =>
    `member/${memberId}/completed-sessions-count`,

  growthworkDetails: ({
    member_id,
    cms_id,
  }: {
    member_id: string;
    cms_id: number;
  }) => `members/${member_id}/growth/details/${cms_id}`,

  journalResult: (id: string) => `growth/${id}/parse_journal_result/`,

  recommendations: ({
    memberId,
    isDefault,
  }: {
    memberId: string;
    isDefault?: boolean;
  }) =>
    `/recommendations/?member_uuid=${memberId}&type=pathway${
      isDefault ? "&default_pathway=true" : ""
    }`,
};

export const wistiaUrls = {
  details: (url: string) => `//fast.wistia.net/oembed?url=${url}`,
};