import React, { useEffect, useState } from "react";
import illustration from "src/assets/images/tasks_order.svg";
import { NotificationPopupLayout } from "src/components";
import { useGetPartCompletedTasks } from "src/api/resolvers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useGetMemberQuery } from "src/api/main";

const NewUpdate = () => {
  const { removeBlockersInTheCurrentPart } = useFlags();
  const { data: completedTasks, isFetching } = useGetPartCompletedTasks();
  const { data: member } = useGetMemberQuery();
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (!completedTasks) return;
    const orderedCompletedTasks = Object.values(completedTasks);
    if (
      !isFetching &&
      !removeBlockersInTheCurrentPart &&
      orderedCompletedTasks.every((isDone) => !isDone)
    ) {
      setIsOpened(true);
    }
  }, [
    completedTasks,
    removeBlockersInTheCurrentPart,
    isFetching,
  ]);

  const closeModal = () => {
    setIsOpened(false);
    if (!member) return;
  };

  return (
    <NotificationPopupLayout
      modal={{ open: isOpened, onClose: closeModal }}
      illustration={illustration}
      title={"New update: Tasks in order"}
      text={
        "Unlock maximum growth by completing your weekly tasks in order. Our data shows that this is key to achieving the best results."
      }
      primaryAction={{
        text: "Got it",
        id: "acknowledge_gw_order_change",
        onClick: closeModal,
      }}
    />
  );
};

export default NewUpdate;
